import type { RawClient, Client } from './types';

export function serializeRawClient(rawClient: RawClient): Client {
  const rawProfile = rawClient.profiles[0];
  return {
    id: rawClient.id,
    firstName: rawClient.first_name,
    lastName: rawClient.last_name,
    email: rawClient.email,
    profiles: [
      {
        profileType: rawProfile.profile_type,
        organizationId: rawProfile.organization_id,
      },
    ],
  };
}
